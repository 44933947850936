<template>
  <span class="text-[#7C8080] text-[11px]">
    <span
      v-if="
        [
          'INQUIRY',
          'QUOTATION_CREATE',
          'QUOTATION_ADD',
          'PURCHASE_CREATE',
          'PURCHASE_COMMIT',
          'PURCHASE_REJECT',
          'PURCHASE_DELIVER',
          'PURCHASE_STOCK_IN'
        ].includes(lastMessage.ext.type)
      "
      class="flex justify-between"
    >
      <span v-if="lastMessage.ext.type === 'INQUIRY'">询价</span>
      <span v-if="lastMessage.ext.type === 'QUOTATION_CREATE'">报价</span>
      <span v-if="lastMessage.ext.type === 'QUOTATION_ADD'">追加报价</span>
      <span v-if="lastMessage.ext.type === 'PURCHASE_CREATE'">下单</span>
      <span v-if="lastMessage.ext.type === 'PURCHASE_COMMIT'">接单</span>
      <span v-if="lastMessage.ext.type === 'PURCHASE_REJECT'">拒单</span>
      <span v-if="lastMessage.ext.type === 'PURCHASE_DELIVER'">发货</span>
      <span v-if="lastMessage.ext.type === 'PURCHASE_STOCK_IN'">入库</span>
      <span class="mx-5px flex-1 truncate">{{
        lastMessage.ext.vehicleBrand ? vehicleBrand : lastMessage.ext.goods
      }}</span>
      <span>{{ date }}</span>
    </span>
    <span v-else-if="['PDF', 'DOC', 'XLS'].includes(lastMessage.ext.type)">[文件]</span>
    <span v-else-if="lastMessage.ext.type === 'FACE'">[表情]</span>
    <span v-else-if="lastMessage.ext.type === 'IMAGE'">[图片]</span>
    <div v-else class="w-full truncate">{{ lastMessage.data }}</div>
  </span>
</template>

<script lang="ts" setup>
import { computed, PropType } from 'vue'
import type { Message } from '@vue-mfe/utils/src/useWebim'
// import { formatDate } from '@vue-mfe/utils'
import moment from 'moment'
// @ts-ignore
const props = defineProps(
  {
    lastMessage: {
      type: Object as PropType<Message>,
      require: true,
      default: () => {
        ext: {
        }
      }
    }
  }
)
const date = computed(
  () => {
    let time = ''
    if (moment(moment()).diff(moment(props.lastMessage.time), 'days') < 3) {
      time = moment(props.lastMessage.time).fromNow()
    } else {
      time = moment(props.lastMessage.time || '').format('YYYY月DD日')
    }
    return time
  }
)
const vehicleBrand = computed(
  () => {
    if (props.lastMessage.ext.vehicleBrand) {
      let str = props.lastMessage.ext.vehicleBrand || ''
      if (props.lastMessage.ext.vehicleSubBrand) str = str + ' ' + props.lastMessage.ext.vehicleSubBrand
      if (props.lastMessage.ext.vehicleSubBrand) str = str + ' ' + props.lastMessage.ext.vehicleSubBrand
      return str
    } else {
      return ''
    }
  }
)
</script>
