<template>
  <div class="h-full flex flex-col">
    <div class="h-[40px] flex items-center justify-between px-20px border-b border-[#D9D9D9]">
      <div>
        <span class="text-[13px] font-bold">{{
          webim.state.current.notes || webim.state.current.friendInfo?.name
        }}</span>
        <span class="ml-[5px] text-[11px] text-[#595959]">{{ webim.state.current.friendInfo?.companyName }}</span>
      </div>
      <a-tooltip placement="bottom" :title="webim.state.current.friendInfo?.cellphone">
        <phone-outlined class="cursor-pointer"></phone-outlined>
      </a-tooltip>
    </div>

    <div class="flex-1 overflow-auto p-[16px]" ref="imContentRef">
      <im-message></im-message>
    </div>

    <div class="h-[100px] flex flex-col border-t border-[#D9D9D9]">
      <div class="h-[24px] p-[7px] flex items-center">
        <im-chat-faces @change="onImChatFacesChange"></im-chat-faces>
        <a-tooltip class="ml-[7px]" placement="topLeft" title="发送图片">
          <a-upload
            :action="store.state.ossData.host"
            :data="store.state.ossData"
            :show-upload-list="false"
            :before-upload="beforeUpload"
            @change="onUploadChange"
            accept=".jpg,.jpeg,.png"
          >
            <PictureOutlined class="cursor-pointer !text-[#999]"></PictureOutlined>
          </a-upload>
        </a-tooltip>

        <a-tooltip class="ml-[10px]" placement="topLeft" title="发送文件">
          <a-upload
            :action="store.state.ossData.host"
            name="file"
            :data="store.state.ossData"
            :show-upload-list="false"
            :before-upload="beforeUploadFile"
            @change="onUploadFileChange"
            accept=".xls,.xlsx,.doc,.docx,.pdf"
          >
            <FolderOpenOutlined class="cursor-pointer !text-[#999]" />
          </a-upload>
        </a-tooltip>
        <!-- <img
          src="@container/assets/icon_card.png"
          @click="sendCardClick"
          class="ml-[7px] w-[22px] h-[22px] cursor-pointer"
        /> -->
        <img
          src="@container/assets/icon_bill.png"
          class="ml-[7px] w-[22px] h-[22px] cursor-pointer"
          @click="onScheduleClick"
          v-if="scheduleShow"
        />
      </div>
      <div class="flex flex-1 justify-between items-center">
        <a-textarea
          placeholder="请输入信息..."
          class="im__input"
          v-model:value="message"
          :maxlength="120"
          @pressEnter.prevent="onSendMessage"
        ></a-textarea>
        <a-button type="link" @click="onSendMessage">发送</a-button>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { nextTick, onMounted, ref, watch, computed } from 'vue'

import { Tooltip as ATooltip, Textarea as ATextarea, Upload as AUpload, notification } from 'ant-design-vue'
import { PhoneOutlined, PictureOutlined, FolderOpenOutlined } from '@ant-design/icons-vue'
import { useWebim, useStore } from '@vue-mfe/utils'

import ImMessage from '@container/components/im-message.vue'
import ImChatFaces from '@container/components/im-chat-faces.vue'

import type { OssState } from '@vue-mfe/utils'

const webim = useWebim()
const store = useStore()

const imContentRef = ref()
const message = ref<string>('')

onMounted(() => (imContentRef.value.scrollTop = imContentRef.value.scrollHeight))

const scheduleShow = computed(
  () => {
    if (localStorage.getItem('ENOQUOTE_ROLE') === 'CLIENT') return webim.state.current.friendInfo?.type.code === 'S'
    if (localStorage.getItem('ENOQUOTE_ROLE') === 'SUPPLIER') return webim.state.current.friendInfo?.type.code === 'C'
  }
)

watch(
  () => webim.state.current.messages?.length,
  () => {
    nextTick(() => (imContentRef.value.scrollTop = imContentRef.value.scrollHeight))
  }
)

watch(
  () => webim.state.current.id,
  () => (message.value = '')
)

const beforeUpload = (file: File) => {
  // if (!['image/png', 'image/jpeg'].includes(file.type)) {
  //   notification.warning({ message: '请上传图片文件' })
  //   return Promise.reject()
  // }
  const ossData: OssState = Object.assign({}, store.state.oss)
  ossData.OSSAccessKeyId = ossData.accessId
  ossData.Signature = ossData.signature
  ossData.key = ossData.dir + '/webim/' + file.name
  store.actions.changeState({ ossData })
  return Promise.resolve(file)
}

const beforeUploadFile = (file: File) => {
  if (
    ![
      'application/pdf',
      'application/vnd.ms-excel',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      'application/msword',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
    ].includes(file.type)
  ) {
    notification.warning({ message: '请上传合适的文件' })
    return Promise.reject()
  }

  const ossData: OssState = Object.assign({}, store.state.oss)
  ossData.OSSAccessKeyId = ossData.accessId
  ossData.Signature = ossData.signature
  ossData.key = ossData.dir + '/webim/' + file.name
  store.actions.changeState({ ossData })
  return Promise.resolve(file)
}

const onUploadChange = (info: any) => {
  const { file } = info
  if (file.status === 'done') {
    webim.sendMessage({ type: 'img', message: `${store.state.ossData.host}/${store.state.ossData.key}` })
  }
}
const onUploadFileChange = (info: any) => {
  const { file } = info
  if (file.status === 'done') {
    const fileSize = `${(file.size / (1024 * 1024)).toFixed(2)}MB`
    switch (file.type) {
      case 'application/pdf':
        webim.sendMessage(
          { type: 'pdf', size: fileSize, message: `${store.state.ossData.host}/${store.state.ossData.key}` }
        )
        break
      case 'application/vnd.ms-excel':
      case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
        webim.sendMessage(
          { type: 'xls', size: fileSize, message: `${store.state.ossData.host}/${store.state.ossData.key}` }
        )
        break
      case 'application/msword':
      case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
        webim.sendMessage(
          { type: 'doc', size: fileSize, message: `${store.state.ossData.host}/${store.state.ossData.key}` }
        )
        break
    }
  } else if (file.status === 'error ') notification.error({ message: '文件上传失败' })
}

// const sendCardClick = () => {
//   webim.sendMessage({ type: 'card', message: '名片' })
// }
const onScheduleClick = () => {
  webim.state.dialog.title = `与 ${webim.state.current.friendInfo?.name} 相关的信息`
  webim.state.dialog.type = 'order'
  webim.state.dialog.visible = true
}

const onImChatFacesChange = (imgUrl: string) =>
  webim.sendMessage({ type: 'face', message: imgUrl }).then(() => (message.value = ''))

const onSendMessage = () => {
  if (!message.value) return
  webim.sendMessage({ message: message.value }).then(() => (message.value = ''))
}
</script>

<style scoped>
.im__input {
  resize: none;
  outline: 0;
  border: 0;
  box-sizing: border-box;
  overflow-y: auto;
  word-break: break-all;
  overflow-wrap: break-word;
}

.im__input:focus,
.im__input:hover {
  outline: 0;
  box-shadow: none;
}
</style>
