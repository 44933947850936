<template>
  <a-menu
    mode="inline"
    :inlineIndent="24"
    :selectedKeys="selectedKeys"
    :openKeys="openKeys"
    @click="onMenuClick"
    v-bind="$attrs"
    class="enoquote-menu"
  >
    <template v-for="menu in menus" :key="menu.id">
      <layout-menu-item :menu="menu"></layout-menu-item>
    </template>
  </a-menu>
</template>

<script lang="ts" setup>
import { computed, h, ref, watch } from 'vue'
// import type { Ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'

import { InfoCircleFilled } from '@ant-design/icons-vue'
import { Menu as AMenu, Modal } from 'ant-design-vue'
import { toLower } from 'lodash-es'

import { useStore, supMenus, cliMenus } from '@vue-mfe/utils'

import LayoutMenuItem from '@container/components/menu-item.vue'

const router = useRouter()
const route = useRoute()
const store = useStore()

const selectedKeys = ref([route.name])
const openKeys = ref<any[]>([])

const isAuthenticated = computed(() => ['A', 'D'].includes(store.state.supplier.status.code))
const onMenuClick = (menu: any) => {
  if (
    localStorage.getItem('ENOQUOTE_ROLE') === 'SUPPLIER' &&
    !isAuthenticated.value &&
    ['supplier-analyze', 'supplier-inventory-shared', 'supplier-inventory-owner', 'supplier-supply-order'].includes(
      menu.key
    )
  ) {
    return Modal.confirm(
      {
        width: 500,
        centered: true,
        content: () =>
          h(
            'div',
            {
              style: {
                height: '100px',
                display: 'flex',
                'flex-direction': 'column',
                'justify-content': 'center',
                'align-items': 'center'
              }
            },
            [
              h(InfoCircleFilled, { style: { color: '#ffd100', fontSize: '60px' } }),
              h(
                'span',
                { style: { fontWeight: 500, fontSize: '16px', marginTop: '31px' } },
                '您尚未认证，无法使用此服务'
              )
            ]
          ),
        okText: () => '去认证',
        cancelText: () => '取消',
        onOk: () => {
          router.push({ name: 'supplier-setting' })
        }
      }
    )
  }
  const menuRouter = { name: menu.key }
  const [_, routePrams] = menu.key.split('_')
  // @ts-ignore
  if (routePrams) menuRouter.params = { [routePrams]: 'add' }
  router.push(menuRouter)
}

const menus = computed(
  () => {
    if (localStorage.ENOQUOTE_ROLE === 'SUPPLIER') return supMenus
    if (localStorage.ENOQUOTE_ROLE === 'CLIENT') return cliMenus
  }
)

const getOpenKeys = () => {
  const getKey = (menus: any[]) => {
    const row = menus.find(
      (item: any) =>
        route.name === toLower(item.id) ||
        (item.children && item.children.find((inner: any) => toLower(inner.id) === route.name))
    )
    if (row) return [toLower(row.id)]
  }
  if (localStorage.ENOQUOTE_ROLE === 'SUPPLIER') return getKey(supMenus)
  else if (localStorage.ENOQUOTE_ROLE === 'CLIENT') return getKey(cliMenus)
}

watch(
  () => route.fullPath,
  () => {
    openKeys.value = getOpenKeys() || []
    selectedKeys.value = [route.name]
  }
)
</script>

<style scoped>
.enoquote-menu.ant-menu-light :deep(.ant-menu-submenu-selected) {
  color: #000;
}

.enoquote-menu.ant-menu-light :deep(.ant-menu-item.ant-menu-item-selected) {
  background: #d0f3f3;
  color: #000;
}

.enoquote-menu.ant-menu-light :deep(.ant-menu-item)::after {
  content: none;
}
</style>
