<template>
  <a-layout style="height: 100vh">
    <a-layout-sider
      :collapsed="collapsed"
      :theme="theme"
      :width="width"
      collapsedWidth="46"
      breakpoint="xxl"
      @breakpoint="onBreakpoint"
    >
      <div class="h-full flex flex-col border-r border-gray-200">
        <div class="h-48px flex items-center justify-center">
          <img v-if="collapsed" src="@container/assets/logo-simple.png" width="32" />
          <img v-else src="@container/assets/logo.png" width="149" />
        </div>

        <div class="flex-auto">
          <layout-menu :collapsed="collapsed" :theme="theme"></layout-menu>
        </div>

        <div
          :class="[
            'h-40px',
            'w-full',
            'flex',
            'items-center',
            'px-20px',
            { 'bg-gray-100': theme !== 'dark' },
            { 'border-t-[1px]': theme === 'dark' },
            { 'border-[#131B24]': theme === 'dark' }
          ]"
        >
          <component
            :class="{ '!text-[#A6ADB4]': theme === 'dark' }"
            :is="collapsed ? MenuUnfoldOutlined : MenuFoldOutlined"
            @click="onCollapseChange"
          ></component>
        </div>
      </div>
    </a-layout-sider>

    <a-layout>
      <a-layout-header style="height: 48px; background: #fff; padding: 20px" class="border-b border-gray-200">
        <div class="h-full w-full flex items-center">
          <div class="flex-auto"></div>

          <!-- <a-tooltip title="消息">
            <BellOutlined />
          </a-tooltip> -->

          <a-dropdown placement="bottomLeft">
            <section class="mx-3 cursor-pointer">
              <a-avatar :src="store.state.user.icon || AvatarImg" class="user-avatar"></a-avatar>
              <span class="ml-5px">{{ store.state.user.name }}</span>
            </section>
            <template #overlay>
              <a-menu @click="onHeaderUserClick">
                <a-menu-item key="name">{{ store.state.user.name }}</a-menu-item>
                <a-menu-item key="workPlace">
                  <span>所属: </span>
                  <span>{{
                    ENOQUOTE_ROLE === 'SUPPLIER' ? store.state.user.supplier?.shortName : store.state.user.branchName
                  }}</span>
                </a-menu-item>
                <a-menu-item key="help">使用帮助</a-menu-item>
                <a-menu-item key="logout">退出</a-menu-item>
              </a-menu>
            </template>
          </a-dropdown>

          <a-tooltip title="设置">
            <SettingOutlined @click="onHeaderSettingClick" />
          </a-tooltip>
        </div>
      </a-layout-header>

      <a-layout-content>
        <a-config-provider :locale="zhCN" :transformCellText="transformCellText">
          <router-view></router-view>
        </a-config-provider>
      </a-layout-content>

      <a-layout-footer>
        <div class="text-center">Copyright © 2021 杭州以诺行汽车科技股份有限公司 咨询电话：400-996-0171</div>
        <div class="flex justify-center items-center mt-[4px]">
          <span>浙ICP备16038648号-1 </span>
          <img width="20" height="20" class="mx-[8px]" src="@container/assets/police-badge.png" alt="" />
          <span>浙公网安备 33011002015331号</span>
        </div>
      </a-layout-footer>
    </a-layout>
  </a-layout>

  <div
    :class="[
      'fixed right-[0] bottom-10px opacity-70 hover:opacity-100',
      webim.state.status && unReadMessages && 'animate-bounce'
    ]"
  >
    <div
      :class="['w-[60px] h-[60px]  flex flex-col items-center justify-center rounded-[4px] bg-white']"
      @click="onWidgetImClick"
    >
      <a-badge :count="unReadMessages">
        <img src="@container/assets/im-message.png" width="24" />
      </a-badge>
      <span>{{ webim.state.status ? '聊一聊' : '未登录' }}</span>
    </div>
  </div>

  <im-modal v-if="webim.state.status"></im-modal>
</template>

<script lang="ts" setup>
import { ref, computed, onMounted } from 'vue'

import {
  Layout as ALayout,
  LayoutSider as ALayoutSider,
  LayoutHeader as ALayoutHeader,
  LayoutContent as ALayoutContent,
  LayoutFooter as ALayoutFooter,
  ConfigProvider as AConfigProvider,
  Tooltip as ATooltip,
  Dropdown as ADropdown,
  Menu as AMenu,
  MenuItem as AMenuItem,
  Avatar as AAvatar,
  Badge as ABadge
} from 'ant-design-vue'

import zhCN from 'ant-design-vue/es/locale/zh_CN'

import { Modal as AModal, message } from 'ant-design-vue'
import { MenuFoldOutlined, MenuUnfoldOutlined, SettingOutlined } from '@ant-design/icons-vue'

import { useRouter } from 'vue-router'

import LayoutMenu from '@container/components/menu.vue'
import ImModal from '@container/components/im-modal.vue'
import AvatarImg from '@container/assets/avatar.png'
import { useStore, useAjax, useWebim } from '@vue-mfe/utils'
// import Cookies from 'js-cookie'

const store = useStore()
const router = useRouter()
const webim = useWebim()
const ENOQUOTE_ROLE = localStorage.getItem('ENOQUOTE_ROLE')
onMounted(
  () => {
    store.actions.changeUser().then(
      (user) => {
        store.actions.changeSupplier()
        store.actions.changeOss()
        if (user.chatUser) {
          webim.state.user = user.chatUser
          useAjax(
            {
              action: `GET /${localStorage.getItem('ENOQUOTE_ROLE')?.toLowerCase()}/security/huanxintoken`,
              success (data) {
                webim.state.accessToken = data[0]
                webim.login()
              },
              fail () {}
            }
          )
        }
      }
    )
  }
)

const transformCellText = ({ text }: { text: string }) => text || '-'
const collapsed = ref(false)
const width = ref(208)
const onBreakpoint = (broken: boolean) => {
  collapsed.value = broken
}

const onCollapseChange = () => (collapsed.value = !collapsed.value)

const onHeaderSettingClick = () => {
  if (localStorage.ENOQUOTE_ROLE === 'SUPPLIER') {
    router.push('/supplier/setting')
  } else {
    router.push('/client/setting')
  }
}

const onHeaderUserClick = (option: any) => {
  switch (option.key) {
    case 'logout':
      if (localStorage.ENOQUOTE_ROLE === 'CLIENT') {
        AModal.confirm(
          {
            iconType: 'warning',
            title: () => '温馨提醒',
            content: () => '系统将跳转到ERP！',
            onOk: () => {
              localStorage.removeItem('ENOQUOTE_ROLE')
              const url =
                import.meta.env.VITE_APP_PROFILE === 'prod'
                  ? 'https://enocloud.enoch-car.com'
                  : 'https://enocloudd.enoch-car.com'
              location.href = url
            }
          }
        )
      } else {
        AModal.confirm(
          {
            iconType: 'warning',
            title: () => '温馨提醒',
            content: () => '是否确认退出系统?',
            onOk: () => {
              useAjax(
                {
                  action: 'POST /supplier/security/logout',
                  success () {
                    router.replace({ name: 'admin-authenticate' }).finally(
                      () => {
                        message.success('成功退出登录')
                        // location.replace(location.origin + location.hash)
                        store.actions.reset()
                        localStorage.removeItem('ENOQUOTE_ROLE')
                        webim.logout()
                      }
                    )
                  }
                }
              )
            }
          }
        )
      }

      break
  }
}

const unReadMessages = computed(
  () =>
    webim.state.friends.reduce(
      (count, cur) => {
        count += cur.messages.reduce((sum, inner) => (sum += inner.isRead ? 0 : 1), 0)
        return count
      },
      0
    )
)
const onWidgetImClick = () => {
  webim.changeState({ visible: true })
}

const theme = computed(
  () => {
    if (localStorage.ENOQUOTE_ROLE === 'SUPPLIER') {
      return 'light'
    } else {
      return 'dark'
    }
  }
)
</script>
