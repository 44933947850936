<template>
  <a-collapse v-model:activeKey="openKeys" :border="false" class="im-roster__collapse">
    <template #expandIcon="{ isActive }">
      <caret-right-outlined :rotate="isActive ? 90 : 0" />
    </template>
    <a-collapse-panel
      v-for="(gr, index) of webim.state.groups"
      :key="gr.id"
      :header="`${gr.name} (${webim.state.friends.filter((item) => item.groupId === gr.id).length})`"
    >
      <template #extra>
        <a-dropdown :trigger="['click']">
          <ellipsis-outlined @click.stop></ellipsis-outlined>
          <template #overlay>
            <a-menu>
              <a-menu-item key="add" @click="onGroupMenuClick(gr, 'add')">新建分组</a-menu-item>
              <a-menu-item key="modify" :disabled="!index" @click="onGroupMenuClick(gr, 'modify')">重命名</a-menu-item>
              <a-menu-item key="delete" :disabled="!index" @click="onGroupMenuClick(gr, 'delete')">删除</a-menu-item>
            </a-menu>
          </template>
        </a-dropdown>
      </template>

      <im-friend :groupId="gr.id"></im-friend>
    </a-collapse-panel>
  </a-collapse>
</template>

<script lang="ts" setup>
import { onMounted, ref } from 'vue'
import {
  Collapse as ACollapse,
  CollapsePanel as ACollapsePanel,
  Menu as AMenu,
  MenuItem as AMenuItem,
  Dropdown as ADropdown,
  message
} from 'ant-design-vue'
import { CaretRightOutlined, EllipsisOutlined } from '@ant-design/icons-vue'
import { useWebim } from '@vue-mfe/utils'
import type { Group } from '@vue-mfe/utils/src/useWebim'

import ImFriend from '@container/components/im-friend.vue'

const webim = useWebim()

const openKeys = ref([0])

onMounted(
  () => {
    openKeys.value = webim.state.current.id
      ? [webim.state.friends.find((item) => item.id === webim.state.current.id)!.groupId]
      : [0]
  }
)

const onGroupMenuClick = (group: Group, key: string) => {
  switch (key) {
    case 'add':
      if (webim.state.groups.length >= 20) return message.warning('最多创建20个分组')
      webim.state.dialog.visible = true
      webim.state.dialog.title = '添加分组'
      webim.state.dialog.type = 'add-group'
      webim.state.dialog.data = { name: '' }
      break
    case 'modify':
      webim.state.dialog.visible = true
      webim.state.dialog.title = '修改分组'
      webim.state.dialog.type = 'modify-group'
      webim.state.dialog.data = Object.assign({}, group)
      break
    case 'delete':
      webim.deleteHuanxinGroup(group.id!).then(webim.getHuanxinGroup)
      break
  }
}
</script>

<style scoped>
.im-roster__collapse :deep(.ant-collapse-item .ant-collapse-content .ant-collapse-content-box) {
  padding: 0;
}
</style>
