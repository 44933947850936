<template>
  <template v-if="!menu.children">
    <a-menu-item :key="toLower(menu.id)">
      <menu-item-wrapper :menuItem="menu"></menu-item-wrapper>
    </a-menu-item>
  </template>

  <template v-else>
    <a-sub-menu :key="toLower(menu.id)">
      <template #title>
        <menu-item-wrapper :menuItem="menu"></menu-item-wrapper>
      </template>
      <template v-for="innerMenu in menu.children" :key="toLower(innerMenu.id)">
        <template v-if="innerMenu.children">
          <menu-item :menu="innerMenu"></menu-item>
        </template>
        <template v-else>
          <a-menu-item :key="toLower(innerMenu.id)">
            <menu-item-wrapper :menuItem="innerMenu"></menu-item-wrapper>
          </a-menu-item>
        </template>
      </template>
    </a-sub-menu>
  </template>
</template>

<script lang="ts" setup>
import { defineComponent, PropType, h, computed } from 'vue'
import { MenuItem as AMenuItem, SubMenu as ASubMenu } from 'ant-design-vue'

import { toLower } from 'lodash-es'

import type { Menu } from '@vue-mfe/utils'
import { SvgIcon } from '@vue-mfe/components'

import { useRoute } from 'vue-router'
const route = useRoute()

defineProps(
  {
    menu: {
      type: Object as PropType<Menu>,
      default: () => ({})
    }
  }
)

const MenuItemWrapper = defineComponent(
  {
    props: {
      menuItem: {
        type: Object as PropType<{ id: string; message: string }>,
        required: true
      }
    },

    setup (props) {
      const svgName = computed(
        (): string => {
          const menuItemId = toLower(props.menuItem.id)
          return route.name === menuItemId ? menuItemId + '-active' : menuItemId
        }
      )
      return () =>
        h(
          'div',
          { class: 'flex items-center' },
          [
            //@ts-ignore
            h('span', { class: 'anticon' }, [h(SvgIcon, { name: svgName.value })]),
            h('span', { class: 'ml-5px' }, props.menuItem.message)
          ]
        )
    }
  }
)
</script>
