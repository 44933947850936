<template>
  <div>
    <a-tooltip placement="topLeft" title="表情">
      <template #content>表情</template>
      <a-popover trigger="click" :visible="popoverVisible" @visibleChange="onPopoverVisibleChange">
        <template #content>
          <div class="w-[250px] grid grid-cols-5">
            <img
              v-for="(img, i) in faces"
              :src="`https://resource.enoch-car.com/enoch-faces/${img}`"
              :key="i"
              @click="onFaceClick(i)"
              class="cursor-pointer w-[30px] h-[30px] transition hover:bg-[#D9D9D9]"
            />
          </div>
        </template>
        <img src="@container/assets/icon_face.png" @click="onPopoverOpen" class="w-[22px] h-[22px] cursor-pointer" />
      </a-popover>
    </a-tooltip>
  </div>
</template>

<script lang="ts" setup>
import { ref } from 'vue'
import { Tooltip as ATooltip, Popover as APopover } from 'ant-design-vue'

const faces = {
  '[):]': 'ee_1.png',
  '[:D]': 'ee_2.png',
  '[;)]': 'ee_3.png',
  '[:-o]': 'ee_4.png',
  '[:p]': 'ee_5.png',
  '[(H)]': 'ee_6.png',
  '[:@]': 'ee_7.png',
  '[:s]': 'ee_8.png',
  '[:$]': 'ee_9.png',
  '[:(]': 'ee_10.png',
  "[:'(]": 'ee_11.png',
  '[<o)]': 'ee_12.png',
  '[(a)]': 'ee_13.png',
  '[8o|]': 'ee_14.png',
  '[8-|]': 'ee_15.png',
  '[+o(]': 'ee_16.png',
  '[|-)]': 'ee_17.png',
  '[:|]': 'ee_18.png',
  '[*-)]': 'ee_19.png',
  '[:-#]': 'ee_20.png',
  '[:-*]': 'ee_22.png',
  '[^o)]': 'ee_21.png',
  '[8-)]': 'ee_23.png',
  '[(|)]': 'ee_24.png',
  '[(u)]': 'ee_25.png',
  '[(S)]': 'ee_26.png',
  '[(*)]': 'ee_27.png',
  '[(#)]': 'ee_28.png',
  '[(R)]': 'ee_29.png',
  '[({)]': 'ee_30.png',
  '[(})]': 'ee_31.png',
  '[(k)]': 'ee_32.png',
  '[(F)]': 'ee_33.png',
  '[(W)]': 'ee_34.png',
  '[(D)]': 'ee_35.png'
}

const emit = defineEmits(['change'])

const popoverVisible = ref<boolean>(false)

const onPopoverOpen = () => (popoverVisible.value = true)
const onPopoverClose = () => (popoverVisible.value = false)

const onPopoverVisibleChange = (visible: boolean) => (popoverVisible.value = visible)

const onFaceClick = (i: string) => {
  //@ts-ignore
  emit('change', `https://resource.enoch-car.com/enoch-faces/${faces[i]}`)
  onPopoverClose()
}
</script>
