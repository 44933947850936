<template>
  <transition name="slide-fade">
    <div v-if="webim.state.visible" class="h-full top-0 right-0 left-0 fixed z-1001" @click.self="onMaskClick">
      <div class="h-[440px] right-[100px] bottom-[20px] w-[760px] absolute overflow-hidden">
        <div class="flex flex-col h-full relative bg-[#FFFFFF]">
          <div class="bg-[#262626] h-[40px] text-white grid grid-cols-[160px,auto]">
            <div class="grid grid-cols-2">
              <div :class="['flex items-center justify-center cursor-pointer']">
                <img src="@container/assets/im-bell.png" width="16" />
                <span class="ml-[4px]">聊一聊</span>
              </div>
            </div>
            <div class="flex px-[20px] items-center justify-end">
              <!-- <a-select
                class="rounded-full w-[200px] im-modal__select-search"
                v-model:value="searchState.value"
                :options="searchState.options"
                show-search
                :filter-option="false"
                :show-arrow="false"
                :not-found-content="searchState.loading ? undefined : null"
                @search="onSearch"
                placeholder="Select users"
              >
                <template v-if="searchState.loading" #notFoundContent>
                  <a-spin size="small" />
                </template>

                <a-select-opt-group label="联系人">
                  <a-select-option></a-select-option>
                </a-select-opt-group>

                <a-select-opt-group label="采购商">
                  <a-select-option></a-select-option>
                </a-select-opt-group>

                <a-select-opt-group label="消息详情">
                  <a-select-option></a-select-option>
                </a-select-opt-group>
              </a-select> -->
            </div>
          </div>

          <div class="flex h-[400px] overflow-hidden">
            <div class="border-r border-[#D9D9D9] w-[180px] overflow-auto">
              <im-group></im-group>
            </div>
            <div class="flex-auto">
              <im-content v-if="webim.state.current.id"></im-content>
            </div>
          </div>
        </div>

        <transition name="slide-fade">
          <div
            v-if="webim.state.dialog.visible"
            class="h-full w-full top-0 left-0 absolute bg-[#000000] bg-opacity-40 flex items-center justify-center"
            @click.self="onWebimDialogClose"
          >
            <div class="w-[400px] bg-white border border-[#D9D9D9] z-999">
              <div class="h-[32px] flex justify-between border-b border-[#D9D9D9] p-[10px]">
                <span class="text-[#262626] text-[12px] font-[600]">{{ webim.state.dialog.title }}</span>
                <close-outlined class="cursor-pointer" @click="webim.state.dialog.visible = false"></close-outlined>
              </div>

              <div class="p-[10px] max-h-[300px] overflow-auto">
                <template v-if="['add-group', 'modify-group'].includes(webim.state.dialog.type)">
                  <a-input placeholder="分组名称" v-model:value="webim.state.dialog.data.name" :maxlength="6"></a-input>
                  <span class="text-[#FF4D4F]" v-if="webim.state.dialog.errorText">{{
                    webim.state.dialog.errorText
                  }}</span>
                </template>

                <template v-if="['remark-friend'].includes(webim.state.dialog.type)">
                  <a-input
                    placeholder="好友备注"
                    v-model:value="webim.state.dialog.data.notes"
                    :maxlength="12"
                  ></a-input>
                </template>

                <template v-if="['show-image'].includes(webim.state.dialog.type)">
                  <div class="text-center">
                    <a-image class="w-full" :src="webim.state.dialog.data" :preview="false"></a-image>
                  </div>
                </template>

                <template v-if="['order'].includes(webim.state.dialog.type) && ENOQUOTE_ROLE === 'CLIENT'">
                  <a-tabs class="my-card" type="card" v-model:activeKey="activeKey">
                    <a-tab-pane tab="询价单" key="inquiry">
                      <e-table
                        class="my-table"
                        :data-source="inquiryData"
                        :columns="inquiryColumns"
                        :loading="inquiryLoading || sendInquryLoading"
                        row-key="id"
                        pagination
                        :paging="inquiryPaging"
                        :method="getInquiry"
                        :scroll="{ y: 120 }"
                        :locale="{ emptyText: '暂无数据' }"
                      >
                        <template #status="{ record }">
                          <a-badge
                            :status="record.inquiryStatus.code === 'P' ? 'warning' : 'success'"
                            :text="record.inquiryStatus.message"
                          />
                        </template>
                        <template #operation="{ record }">
                          <span class="flex items-center operation-model">
                            <a class="text-primary mr-[5px]" @click="onInquiryClick(record)">查看</a>
                            <a v-if="record.inquiryStatus.code === 'C'" type="link" @click="sendInquiryClick(record)">
                              <img class="w-[18px]" src="@container/assets/re-send.png" alt="" />
                            </a>
                          </span>
                        </template>
                      </e-table>
                    </a-tab-pane>
                    <a-tab-pane tab="采购单" key="purchase">
                      <e-table
                        class="my-table"
                        :data-source="inquiryPurchaseData"
                        :columns="inquiryPurchaseColumns"
                        :loading="inquiryPurchaseLoading || sendInquiryPurchaseLoading"
                        row-key="id"
                        pagination
                        :paging="inquiryPurchasePaging"
                        :method="getInquiryPurchase"
                        :scroll="{ y: 120 }"
                        :locale="{ emptyText: '暂无数据' }"
                      >
                        <template #status="{ record }">
                          <a-badge v-if="record.status.code === 'P'" status="error" :text="record.status.message" />
                          <a-badge v-if="record.status.code === 'C'" status="success" :text="record.status.message" />
                          <a-badge v-if="record.status.code === 'R'" status="default" :text="record.status.message" />
                        </template>
                        <template #operation="{ record }">
                          <span class="flex items-center operation-model">
                            <a class="text-primary mr-[5px]" @click="onInquiryPurchaseClick(record)">查看</a>
                            <a v-if="record.status.code === 'P'" type="link" @click="sendInquiryPurchaseClick(record)">
                              <img class="w-[18px]" src="@container/assets/re-send.png" alt="" />
                            </a>
                          </span>
                        </template>
                      </e-table>
                    </a-tab-pane>
                    <a-tab-pane tab="公司信息" key="company">
                      <div class="px-[40px]">
                        <a-form class="my-form" :labelCol="{ style: { width: '80px' } }">
                          <a-form-item label="公司全称">
                            <span>{{ friendCompanyInfo.companyName }}</span>
                          </a-form-item>
                          <a-form-item label="公司电话：">
                            <div class="flex items-center">
                              <span id="supplierName">{{ friendCompanyInfo.companyPhone }}</span>
                              <span class="ml-[6px]">
                                <img
                                  class="w-[16px] h-[16px] cursor-pointer"
                                  src="@container/assets/icon_copy.png"
                                  @click="copyClick('supplierName')"
                                  alt=""
                                />
                              </span>
                            </div>
                          </a-form-item>
                          <a-form-item label="公司地址：">
                            <span>{{ friendCompanyInfo.companyAddress }}</span>
                          </a-form-item>
                        </a-form>
                      </div>
                    </a-tab-pane>
                  </a-tabs>
                </template>

                <template v-if="['order'].includes(webim.state.dialog.type) && ENOQUOTE_ROLE === 'SUPPLIER'">
                  <a-tabs class="my-card" type="card" v-model:activeKey="activeKeyTwo">
                    <a-tab-pane tab="询价单" key="quotation">
                      <e-table
                        class="my-table"
                        :data-source="quotationData"
                        :columns="quotationColumns"
                        :loading="quotationLoading || sendQuotationLoading"
                        row-key="id"
                        pagination
                        :paging="quotationPaging"
                        :method="getQuotation"
                        :scroll="{ y: 120 }"
                        :locale="{ emptyText: '暂无数据' }"
                      >
                        <template #status="{ record }">
                          <a-badge
                            :status="record.supplierQuoted.code === 'N' ? 'warning' : 'success'"
                            :text="record.supplierQuoted.code === 'N' ? '待报价' : '已报价'"
                          />
                        </template>
                        <template #operation="{ record }">
                          <span class="flex items-center operation-model">
                            <a class="text-primary mr-[5px]" @click="onQuotationClick(record)">查看</a>
                            <a
                              v-if="record.supplierQuoted.code === 'Y'"
                              type="link"
                              @click="sendQuotationClick(record)"
                            >
                              <img class="w-[18px]" src="@container/assets/re-send.png" alt="" />
                            </a>
                          </span>
                        </template>
                      </e-table>
                    </a-tab-pane>
                    <a-tab-pane tab="订单" key="order">
                      <e-table
                        class="my-table"
                        :data-source="orderData"
                        :columns="orderColumns"
                        :loading="orderLoading || sendOrderLoading"
                        row-key="id"
                        pagination
                        :paging="orderPaging"
                        :method="getOrder"
                        :scroll="{ y: 120 }"
                        :locale="{ emptyText: '暂无数据' }"
                      >
                        <template #status="{ record }">
                          <a-badge v-if="record.status.code === 'P'" status="error" :text="record.status.message" />
                          <a-badge v-if="record.status.code === 'C'" status="success" :text="record.status.message" />
                          <a-badge v-if="record.status.code === 'R'" status="default" :text="record.status.message" />
                        </template>
                        <template #operation="{ record }">
                          <span class="flex items-center operation-model">
                            <a class="text-primary mr-[5px]" @click="onOrderClick(record)">查看</a>
                            <a v-if="record.status.code === 'C'" type="link" @click="sendOrderClick(record)">
                              <img class="w-[18px]" src="@container/assets/re-send.png" alt="" />
                            </a>
                          </span>
                        </template>
                      </e-table>
                    </a-tab-pane>
                    <a-tab-pane tab="公司信息" key="company">
                      <div class="px-[40px]">
                        <a-form class="my-form" :labelCol="{ style: { width: '80px' } }">
                          <a-form-item label="公司全称：">
                            <span>{{ friendCompanyInfo.companyName }}</span>
                          </a-form-item>
                          <!-- <a-form-item label="税号">
                            <span>{{ getClientInfoData[0].taxNo || '' }}</span>
                          </a-form-item> -->
                          <a-form-item label="公司电话">
                            <div class="flex items-center">
                              <span id="clientName">{{ friendCompanyInfo.companyPhone }}</span>
                              <span class="ml-[6px]">
                                <img
                                  class="w-[16px] h-[16px]"
                                  @click="copyClick('supplierName')"
                                  src="@container/assets/icon_copy.png"
                                  alt=""
                                />
                              </span>
                            </div>
                          </a-form-item>
                          <a-form-item label="公司地址">
                            <span>{{ friendCompanyInfo.companyAddress }}</span>
                          </a-form-item>
                          <!-- <a-form-item label="开户行">
                            <span>{{ getClientInfoData[0].bankName || '' }}</span>
                          </a-form-item>
                          <a-form-item label="银行账号">
                            <span>{{ getClientInfoData[0].bankAccount || '' }}</span>
                          </a-form-item> -->
                        </a-form>
                      </div>
                    </a-tab-pane>
                  </a-tabs>
                </template>
              </div>

              <div
                v-if="['add-group', 'modify-group', 'remark-friend', 'show-image'].includes(webim.state.dialog.type)"
                class="flex justify-end p-[10px]"
              >
                <a-button type="primary" @click="onDialogSubmitClick">确定</a-button>
              </div>
            </div>
          </div>
        </transition>
      </div>
    </div>
  </transition>
</template>

<script lang="ts" setup>
import { reactive, watch, ref } from 'vue'

import {
  // Select as ASelect,
  // SelectOptGroup as ASelectOptGroup,
  // SelectOption as ASelectOption,
  // Spin as ASpin,
  Image as AImage,
  Tabs as ATabs,
  Badge as ABadge,
  TabPane as ATabPane,
  message
} from 'ant-design-vue'
import { CloseOutlined } from '@ant-design/icons-vue'
// import { debounce } from 'lodash-es'
import { useRouter } from 'vue-router'
import moment from 'moment'

import { useWebim, formatDate, useTable2, useAjax, formatArea } from '@vue-mfe/utils'

interface InquiryState {
  id: number
  branchName: string
  vehicleBrand: string
  containGoods: string
  quoteSupplierCount: number
  commitDatetime: string
  inquiryStatus: { code: ''; message: '' }
}

interface InquiryPurchaseState {
  id: number
  serialNo: string
  preparedDatetime: string
}

import ImGroup from '@container/components/im-group.vue'
import ImContent from '@container/components/im-content.vue'

const onMaskClick = () => webim.changeState({ visible: false })

const webim = useWebim()

const router = useRouter()

const ENOQUOTE_ROLE = localStorage.getItem('ENOQUOTE_ROLE')

// const searchState = reactive(
//   {
//     value: '',
//     options: [],
//     loading: false
//   }
// )

// const onSearch = debounce(() => {}, 1000)

function onDialogSubmitClick () {
  switch (webim.state.dialog.type) {
    case 'add-group':
      if (webim.state.groups.find((item) => item.name === webim.state.dialog.data.name)) {
        webim.state.dialog.errorText = `"${webim.state.dialog.data.name}"分组已存在，请重新输入`
        return
      }
      webim.postHuanxinGroup().then(webim.getHuanxinGroup)
      break
    case 'modify-group':
      webim.putHuanxinGroup().then(webim.getHuanxinGroup)
      break
    case 'remark-friend':
      webim.noteHuanxinFriend().then(webim.getHuanxinFriend)
      break
  }
  onWebimDialogClose()
}

function onWebimDialogClose () {
  webim.state.dialog.errorText = ''
  webim.state.dialog.visible = false
}

const activeKey = ref('inquiry')

const {
  data: inquiryData,
  loading: inquiryLoading,
  columns: inquiryColumns,
  paging: inquiryPaging,
  run: getInquiry
} = useTable2<InquiryState>(
  {
    columns: [
      {
        title: '车型',
        ellipsis: true,
        customRender: ({ record }) =>
          record.vin
            ? `${record.vehicleBrand}${record.vehicleSubBrand ? ' ' + record.vehicleSubBrand : ''} ${
                record.vehicleSystem
              } ${record.vehicleYear} ${record.vehicleDisplacement}`
            : record.vehicleBrand
      },
      {
        title: '询价时间',
        ellipsis: true,
        customRender: ({ record }) => formatDate(record.commitDatetime)
      },
      { title: '状态', ellipsis: true, dataIndex: 'inquiryStatus.message', slots: { customRender: 'status' } },
      { title: '操作', slots: { customRender: 'operation' } }
    ],
    paging: {
      pageSize: 5
    },
    ajax: {
      get: {
        action: 'GET /client/inquiry',
        params: () => ({ supplierChatUserId: webim.state.current.friend }),
        lazy: true
      }
    }
  }
)

const {
  data: inquiryPurchaseData,
  loading: inquiryPurchaseLoading,
  columns: inquiryPurchaseColumns,
  paging: inquiryPurchasePaging,
  run: getInquiryPurchase
} = useTable2<InquiryPurchaseState>(
  {
    columns: [
      // { title: '订单号', dataIndex: 'serialNo', ellipsis: true },
      {
        title: '车型',
        ellipsis: true,
        customRender: ({ record }) =>
          record.inquiry.vin
            ? `${record.inquiry.vehicleBrand}${
                record.inquiry.vehicleSubBrand ? ' ' + record.inquiry.vehicleSubBrand : ''
              } ${record.inquiry.vehicleSystem} ${record.inquiry.vehicleYear} ${record.inquiry.vehicleDisplacement}`
            : '-'
      },
      {
        title: '下单时间',

        customRender: ({ record }) => formatDate(record.preparedDatetime),
        ellipsis: true
      },
      { title: '状态', ellipsis: true, slots: { customRender: 'status' } },
      { title: '操作', slots: { customRender: 'operation' } }
    ],
    paging: {
      pageSize: 5
    },
    ajax: {
      get: {
        action: 'GET /client/inquiry/purchase/quick',
        params: () => ({ supplierChatUserId: webim.state.current.friend }),
        lazy: true
      }
    }
  }
)

const activeKeyTwo = ref('quotation')
const {
  data: quotationData,
  loading: quotationLoading,
  columns: quotationColumns,
  paging: quotationPaging,
  run: getQuotation
} = useTable2<InquiryState>(
  {
    columns: [
      {
        title: '车型',
        ellipsis: true,
        customRender: ({ record }) =>
          record.vin
            ? `${record.vehicleBrand}${record.vehicleSubBrand ? ' ' + record.vehicleSubBrand : ''} ${
                record.vehicleSystem
              } ${record.vehicleYear} ${record.vehicleDisplacement}`
            : record.vehicleBrand
      },
      { title: '询价时间', ellipsis: true, customRender: ({ record }) => formatDate(record.commitDatetime) },
      { title: '状态', ellipsis: true, slots: { customRender: 'status' } },
      { title: '操作', slots: { customRender: 'operation' } }
    ],
    paging: {
      pageSize: 5
    },
    ajax: {
      get: {
        action: 'GET /supplier/quotation/inquiry',
        params: () => ({ clientChatUserId: webim.state.current.friend }),
        lazy: true
      }
    }
  }
)

const {
  data: orderData,
  loading: orderLoading,
  columns: orderColumns,
  paging: orderPaging,
  run: getOrder
} = useTable2<InquiryPurchaseState>(
  {
    columns: [
      {
        title: '车型',
        ellipsis: true,
        customRender: ({ record }) =>
          record.inquiry.vin
            ? `${record.inquiry.vehicleBrand}${
                record.inquiry.vehicleSubBrand ? ' ' + record.inquiry.vehicleSubBrand : ''
              } ${record.inquiry.vehicleSystem} ${record.inquiry.vehicleYear} ${record.inquiry.vehicleDisplacement}`
            : '-'
      },
      // { title: '订单号', dataIndex: 'serialNo', ellipsis: true },
      { title: '下单时间', customRender: ({ record }) => formatDate(record.preparedDatetime), ellipsis: true },
      { title: '状态', ellipsis: true, slots: { customRender: 'status' } },
      { title: '操作', slots: { customRender: 'operation' } }
    ],
    paging: {
      pageSize: 5
    },
    ajax: {
      get: {
        action: 'GET /supplier/purchase',
        params: () => ({ clientChatUserId: webim.state.current.friend }),
        lazy: true
      }
    }
  }
)

function onInquiryClick (record: InquiryState) {
  router.replace({ path: `/client/purchase/inquiry/quotation`, query: { id: record.id } })
  webim.state.visible = false
}

function onInquiryPurchaseClick (row: InquiryPurchaseState) {
  router.replace({ path: `/client/purchase/order/${row.id}` })
  webim.state.visible = false
}
function onQuotationClick (row: any) {
  router.replace({ path: `/supplier/supply/quote/${row.id}` })
  webim.state.visible = false
}
function onOrderClick (row: any) {
  router.replace({ path: `/supplier/supply/order/${row.id}` })
  webim.state.visible = false
}

const firstId = ref('')

const puhsMessage = (data: any) => {
  const { to, from, ext, sourceMsg } = data
  const friend = webim.state.friends.find((item) => item.friendInfo.chatAccount === to)
  if (friend) {
    friend.messages.push(
      {
        id: `${friend.messages.length}`,
        to,
        type: 'chat',
        contentsType: 'TEXT',
        data: sourceMsg,
        sourceMsg,
        from,
        time: +moment(),
        isRead: true,
        ext
      }
    )
    webim.state.current = friend
  }
}
const { run: sendInqury, loading: sendInquryLoading } = useAjax(
  {
    action: 'POST /client/inquiry/chat/:inquiryId/:supplierChatUserId',
    params: () => [firstId.value, webim.state.current.friend],
    success: (_, res) => puhsMessage(res),
    lazy: true
  }
)
const sendInquiryClick = (row: any) => {
  firstId.value = row.id
  sendInqury()
}

const { run: sendInquiryPurchase, loading: sendInquiryPurchaseLoading } = useAjax(
  {
    action: 'POST /client/inquiry/purchase/chat/:purchaseId/:supplierChatUserId',
    params: () => [firstId.value, webim.state.current.friend],
    success: (_, res) => puhsMessage(res),
    lazy: true
  }
)
const sendInquiryPurchaseClick = (row: any) => {
  firstId.value = row.id
  sendInquiryPurchase()
}

const { run: sendQuotation, loading: sendQuotationLoading } = useAjax(
  {
    action: 'POST /supplier/quotation/chat/:inquiryId/:clientChatUserId',
    params: () => [firstId.value, webim.state.current.friend],
    success: (_, res) => puhsMessage(res),
    lazy: true
  }
)

const sendQuotationClick = (row: any) => {
  firstId.value = row.id
  sendQuotation()
}
const { run: sendOrder, loading: sendOrderLoading } = useAjax(
  {
    action: 'POST /supplier/purchase/chat/:inquiryId/:clientChatUserId',
    params: () => [firstId.value, webim.state.current.friend],
    success: (_, res) => puhsMessage(res),
    lazy: true
  }
)
const sendOrderClick = (row: any) => {
  firstId.value = row.id
  sendOrder()
}

const friendCompanyInfo = reactive(
  {
    companyName: '',
    companyAddress: '',
    companyPhone: ''
  }
)
const { run: getSupplierInfo } = useAjax(
  {
    action: 'GET /client/security/supplier/:supplierId',
    params: () => webim.state.current.friendInfo?.supplierId,
    success (data) {
      friendCompanyInfo.companyName = data[0].name
      friendCompanyInfo.companyAddress = `${formatArea(data[0].area)}${data[0].address || ''}`
      friendCompanyInfo.companyPhone = data[0].manager.cellphone
    },
    lazy: true
  }
)
const { run: getClientInfo } = useAjax(
  {
    action: 'GET /supplier/security/tenant/:tenantId',
    params: () => webim.state.current.friendInfo?.tenantId,
    success (data) {
      friendCompanyInfo.companyName = data[0].name
      friendCompanyInfo.companyAddress = `${formatArea(data[0].area)}${data[0].address || ''}`
      friendCompanyInfo.companyPhone = data[0].telephone
    },
    lazy: true
  }
)
// const { data: invoiceData, run: getClientInvoice } = useAjax(
//   {
//     action: 'GET /client/invoice',
//     lazy: true
//   }
// )

const copyClick = (eleId: string) => {
  const input = document.createElement('input')
  input.value = document.querySelector(`#${eleId}`)?.innerHTML || ''
  document.body.appendChild(input)
  input.select()
  // eslint-disable-next-line
  document.execCommand('Copy')
  document.body.removeChild(input)
  message.success('复制成功!')
}
watch(
  () => webim.state.dialog.visible,
  (visible) => {
    if (visible && webim.state.dialog.type === 'order') {
      if (ENOQUOTE_ROLE === 'CLIENT') {
        getInquiry()
        getInquiryPurchase()
        getSupplierInfo()
      } else {
        getQuotation()
        getOrder()
        getClientInfo()
      }
    }
  }
)

watch(
  () => webim.state.visible,
  (visible) => {
    if (!visible) {
      webim.state.dialog.visible = false
    }
  }
)
</script>

<style scoped>
.slide-fade-enter-active {
  transition: all 0.2s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.2s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(400px);
  opacity: 0;
}

.im-modal {
  z-index: 50;
}

.my-table :deep(.ant-table-thead th),
.my-table :deep(.ant-table-tbody td) {
  padding: 10px !important;
  font-size: 12px !important;
}
.my-table :deep(.ant-table-pagination),
.my-card :deep(.ant-tabs-tab) {
  font-size: 12px !important;
}
.my-form :deep(.ant-row.ant-form-item) {
  margin-bottom: 0px;
}
</style>
