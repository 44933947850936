<template>
  <div v-for="message of webim.state.current.messages">
    <div
      :class="[
        'flex flex-col py-5px text-[12px] text-[#595959]',
        message.from.toLowerCase() === webim.state.user.chatAccount.toLowerCase() ? 'items-end' : 'items-start'
      ]"
    >
      <span class="text-[#7C8080]">{{ formatDate(message.time, 'YYYY-MM-DD HH:mm') }}</span>
      <div
        :class="[
          'max-w-[500px]',
          'bg-[#F5F5F5]',
          'rounded-[8px]',
          { 'bg-[#EBFCFA]': message.from.toLowerCase() === webim.state.user.chatAccount.toLowerCase() }
        ]"
      >
        <div class="flex flex-col">
          <div class="flex">
            <div
              v-if="
                message.ext.type &&
                [
                  'INQUIRY',
                  'QUOTATION_CREATE',
                  'QUOTATION_ADD',
                  'PURCHASE_CREATE',
                  'PURCHASE_COMMIT',
                  'PURCHASE_REJECT',
                  'PURCHASE_DELIVER',
                  'PURCHASE_STOCK_IN'
                ].includes(message.ext.type)
              "
              class="py-[10px] w-[270px] cursor-pointer"
              @click="onMessageOperationClick(message)"
            >
              <div class="flex items-center justify-between px-[12px]">
                <span>{{ message.data }}</span>
                <img class="w-[16px] h-[16px] cursor-pointer" src="@container/assets/arrow_right.png" />
              </div>
              <div class="w-full h-[1px] bg-[#D1D8D8] my-[10px]"></div>
              <div class="flex items-center px-[12px]">
                <span
                  :class="[
                    'px-[8px]',
                    'py-[2px]',
                    'bg-[#FF9838]',
                    'text-white',
                    'rounded-[20px]',
                    { '!bg-[#18BCBC]': message.from.toLowerCase() === webim.state.user.chatAccount.toLowerCase() }
                  ]"
                >
                  <span v-if="message.ext.type === 'INQUIRY'">询价</span>
                  <span v-if="message.ext.type === 'QUOTATION_CREATE'">报价</span>
                  <span v-if="message.ext.type === 'QUOTATION_ADD'">追加报价</span>
                  <span v-if="message.ext.type === 'PURCHASE_CREATE'">下单</span>
                  <span v-if="message.ext.type === 'PURCHASE_COMMIT'">接单</span>
                  <span v-if="message.ext.type === 'PURCHASE_REJECT'">拒单</span>
                  <span v-if="message.ext.type === 'PURCHASE_DELIVER'">发货</span>
                  <span v-if="message.ext.type === 'PURCHASE_STOCK_IN'">入库</span>
                </span>
                <span class="ml-[8px] flex-1 truncate text-[14px] text-[#262626] font-bold">{{
                  message.ext.goods
                }}</span>
              </div>
            </div>
            <div v-else-if="message.ext.type && message.ext.type === 'FACE'" class="px-[12px] py-[10px]">
              <img :src="message.ext.url" width="32" height="32" />
            </div>

            <div v-else-if="message.ext.type && message.ext.type === 'IMAGE'" class="px-[12px] py-[10px]">
              <img class="w-[100px] h-[100px]" :src="message.ext.url" @click="onMessageOperationClick(message)" />
            </div>
            <div
              v-else-if="message.ext.type && ['PDF', 'DOC', 'XLS'].includes(message.ext.type)"
              class="cursor-pointer px-[12px] py-[10px]"
              @click="downLoad(message.ext)"
            >
              <div class="flex w-[180px]">
                <div class="mr-40px">
                  <p class="w-[100px] truncate">{{ message.ext.url.split('/webim/')[1].replace('\.*', '') }}</p>
                  <p class="mt-8px">{{ message.ext.size || '' }}</p>
                </div>
                <img
                  v-if="message.ext.type === 'PDF'"
                  class="w-[36px] h-[48px]"
                  src="@container/assets/img_pdf.png"
                  alt=""
                />
                <img
                  v-if="message.ext.type === 'DOC'"
                  class="w-[36px] h-[48px]"
                  src="@container/assets/img_doc.png"
                  alt=""
                />
                <img
                  v-if="message.ext.type === 'XLS'"
                  class="w-[36px] h-[48px]"
                  src="@container/assets/img_xls.png"
                  alt=""
                />
              </div>
            </div>

            <!-- <div v-else-if="message.ext.type && message.ext.type === 'CARD'">
              <img class="w-[80px] h-[80px]" src="@container/assets/icon_card.png" alt="" />
              <span>{{ '名片' }}</span>
            </div> -->

            <div v-else class="px-[12px] py-[10px] overflow-ellipsis break-all flex-1">{{ message.data }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useWebim, formatDate } from '@vue-mfe/utils'
import { useRouter } from 'vue-router'
import type { Message } from '@vue-mfe/utils/src/useWebim'
const router = useRouter()

const webim = useWebim()
const ENOQUOTE_ROLE = localStorage.getItem('ENOQUOTE_ROLE')
function onMessageOperationClick (message: Message) {
  switch (message.ext.type) {
    case 'IMAGE':
      webim.state.dialog.title = '查看图片'
      webim.state.dialog.type = 'show-image'
      webim.state.dialog.data = message.ext.url
      webim.state.dialog.visible = true
      break
    case 'INQUIRY':
    case 'QUOTATION_CREATE':
    case 'QUOTATION_ADD':
      if (ENOQUOTE_ROLE === 'CLIENT') {
        router.replace(
          {
            path: '/redirect' + '/client/purchase/inquiry/quotation',
            query: { id: message.ext.entityId }
          }
        )
      }
      if (ENOQUOTE_ROLE === 'SUPPLIER') {
        router.replace({ path: `/supplier/supply/quote/${message.ext.entityId}` })
      }
      webim.changeState({ visible: false })
      break
    case 'PURCHASE_CREATE':
    case 'PURCHASE_COMMIT':
    case 'PURCHASE_REJECT':
    case 'PURCHASE_DELIVER':
    case 'PURCHASE_STOCK_IN':
      if (ENOQUOTE_ROLE === 'CLIENT') {
        router.replace(
          {
            path: '/redirect' + `/client/purchase/order/${message.ext.entityId}`
          }
        )
      }
      if (ENOQUOTE_ROLE === 'SUPPLIER') {
        router.replace(
          {
            path: '/redirect' + `/supplier/supply/order/${message.ext.entityId}`
          }
        )
      }
      webim.changeState({ visible: false })
      break
  }
}
const downLoad = (etc: any) => {
  window.open(etc.url)
}
</script>

<style scoped>
.im-message__body {
  max-width: 500px;
  word-break: break-all;
}

.im-message__body :deep(.image) {
  width: 100px;
  height: 100px;
}
</style>
