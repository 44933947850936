<template>
  <div
    v-for="friend of data"
    :key="friend.id"
    :class="['h-[60px] cursor-pointer flex flex-col', webim.state.current.id === friend.id && 'bg-[#F5F7F7]']"
    @click="webim.changeCurrent(friend)"
  >
    <div class="flex-1 flex flex-col px-[5px] grid-cols-1 items-center justify-between">
      <div class="flex-1 grid grid-cols-[1fr,auto,1fr] grid-rows-1 items-center">
        <div>
          <a-badge :count="unReadMessageCount(friend)">
            <div class="bg-primary flex rounded-1/2 h-[30px] text-white w-[30px] items-center justify-center">
              {{ (friend.notes || friend.friendInfo.name).slice(0, 1) }}
            </div>
          </a-badge>
        </div>
        <div class="w-[100px] truncate">
          <span class="ml-[10px] font-bold text-[13px]">{{ friend.notes || friend.friendInfo.name }}</span>
        </div>
        <div>
          <a-dropdown :trigger="['click']">
            <ellipsis-outlined @click.stop></ellipsis-outlined>
            <template #overlay>
              <a-menu>
                <a-menu-item @click="onFriendMenuClick(friend, 'remark')">备注</a-menu-item>
                <a-sub-menu title="移动至">
                  <a-menu-item v-for="group of webim.state.groups" @click="onFriendMenuClick(friend, 'move', group)">
                    {{ group.name }}
                  </a-menu-item>
                </a-sub-menu>
                <a-menu-item @click="onFriendMenuClick(friend, 'delete')">删除</a-menu-item>
              </a-menu>
            </template>
          </a-dropdown>
        </div>
      </div>

      <div class="h-[20px] w-full" v-if="friend.messages && friend.messages.length">
        <im-tips :lastMessage="friend.messages[friend.messages.length - 1]"></im-tips>
        <!-- <span class="text-gray-500 text-[11px] truncate">
          {{ friendMessage(friend) }}</span>            -->
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue'
import {
  Menu as AMenu,
  MenuItem as AMenuItem,
  SubMenu as ASubMenu,
  Dropdown as ADropdown,
  Badge as ABadge
} from 'ant-design-vue'
import { EllipsisOutlined } from '@ant-design/icons-vue'
import { useWebim } from '@vue-mfe/utils'
import type { Group, Friend } from '@vue-mfe/utils/src/useWebim'

import ImTips from '@container/components/im-tips.vue'
const webim = useWebim()

const props = defineProps<{ groupId: number }>()

const data = computed(() => webim.state.friends.filter((item) => item.groupId === props.groupId))

const unReadMessageCount = (friend: Friend) => friend.messages.reduce((sum, cur) => (sum += cur.isRead ? 0 : 1), 0)

const onFriendMenuClick = (friend: Friend, key: string, group?: Group) => {
  switch (key) {
    case 'remark':
      webim.state.dialog.visible = true
      webim.state.dialog.title = '好友备注'
      webim.state.dialog.type = 'remark-friend'
      webim.state.dialog.data = Object.assign({}, friend)
      if (!webim.state.dialog.data.notes) webim.state.dialog.data.notes = friend.friendInfo.name
      break
    case 'move':
      webim.state.dialog.data = Object.assign({}, friend)
      webim.state.dialog.data.groupId = group?.id
      webim.groupHuanxinFriend().then(webim.getHuanxinFriend)
      break
    case 'delete':
      webim.deleteHuanxinFriend(friend.id!).then(webim.getHuanxinFriend)
      break
  }
}

// const friendMessage = (friend: Friend) => {
//   if (!friend.messages.length) return ''
//   const message = friend.messages[friend.messages.length - 1]
//   let tips = ''
//       // <span v-if="message.ext.type === 'INQUIRY'">询价</span>
//       //             <span v-if="message.ext.type === 'QUOTATION_CREATE'">报价</span>
//       //             <span v-if="message.ext.type === 'QUOTATION_ADD'">追加报价</span>
//       //             <span v-if="message.ext.type === 'PURCHASE_CREATE'">下单</span>
//       //             <span v-if="message.ext.type === 'PURCHASE_COMMIT'">接单</span>
//       //             <span v-if="message.ext.type === 'PURCHASE_REJECT'">拒单</span>
//       //             <span v-if="message.ext.type === 'PURCHASE_DELIVER'">发货</span>
//       //             <span v-if="message.ext.type === 'PURCHASE_STOCK_IN'">入库</span>
//   if (message.ext.type) {
//     switch (message.ext.type) {
//       case 'FACE':
//         tips = '[表情]'
//         break
//       case 'IMAGE':
//         tips = '[图片]'
//         break
//       case 'PDF':
//       case 'DOC':
//       case 'XLS':
//         tips = '[文件]'
//         break
//       case 'INQUIRY':
//         tips = `报价`
//     }
//     tips = '[单据信息]'
//   } else tips = message.data
//   return {
//    tips,
//   }
// }
</script>
